import React from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { ILimbusEgo } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './lc-character-dynamic.scss';
import { SectionHeader } from '../modules/common/components/section-header';
import { Alert, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { LCRarity } from '../modules/lc/common/components/lc-rarity';
import { LCAffinity } from '../modules/lc/common/components/lc-affinity';
import { LCSpecialitiesArray } from '../modules/lc/model/lc-spec';
import lodash from 'lodash';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(LCSpecialitiesArray, {
    value: speciality
  });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

interface ILimbusEgoNodes {
  nodes: ILimbusEgo[];
}

interface ILimbusEgoEntry {
  currentUnit: ILimbusEgoNodes;
}

interface IProps {
  data: ILimbusEgoEntry;
}

const LimbusEgoDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  const fullImage = character.imageFull
    ? getImage(character.imageFull.localFile.childImageSharp)
    : null;

  const image = character.imageSmall
    ? getImage(character.imageSmall.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-limbus'}
      game="lc"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/ego">EGO</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${character.name}`}
          />
        )}
        <div className="character-details">
          <h1>
            {character.name}{' '}
            {character.isNew && <span className="tag new">NEW!</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <LCRarity rarity={character.rarity} />
            <LCAffinity affinity={character.affinity} />
          </div>
        </div>
      </div>
      <div id="section-profile" className="id-section">
        <SectionHeader title="Profile" />
        <Row xs={1} xl={2} xxl={3} className="info-list">
          <Col>
            <h5>Personal information</h5>
            <div className="info-list-row">
              <div className="category">Name</div>
              <div className="details">{character.name}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Sinner</div>
              <div className="details">{character.character}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Rarity</div>
              <div className="details">{character.rarity}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Source</div>
              <div className="details">
                {character.source ? character.source : '-'}
              </div>
            </div>
            <div className="info-list-row">
              <div className="category">Season</div>
              <div className="details">
                Season {character.season === '0' ? '-' : character.season}
              </div>
            </div>
          </Col>
          <Col>
            <h5>Combat information</h5>
            <div className="info-list-row">
              <div className="category">Sanity Cost</div>
              <div className="details">
                {character.sanityCost && character.sanityCost}
              </div>
            </div>
            {character.cost.cost_1 && (
              <>
                <div className="info-list-row">
                  <div className="category">Cost 1</div>
                  <div className="details">
                    <span
                      className={`limbus-affinity ${character.cost.cost_1}`}
                    >
                      {character.cost.cost_1}
                    </span>{' '}
                    x{character.cost.cost_1_v}
                  </div>
                </div>
              </>
            )}
            {character.cost.cost_2 && (
              <>
                <div className="info-list-row">
                  <div className="category">Cost 2</div>
                  <div className="details">
                    <span
                      className={`limbus-affinity ${character.cost.cost_2}`}
                    >
                      {character.cost.cost_2}
                    </span>{' '}
                    x{character.cost.cost_2_v}
                  </div>
                </div>
              </>
            )}
            {character.cost.cost_3 && (
              <>
                <div className="info-list-row">
                  <div className="category">Cost 3</div>
                  <div className="details">
                    <span
                      className={`limbus-affinity ${character.cost.cost_3}`}
                    >
                      {character.cost.cost_3}
                    </span>{' '}
                    x{character.cost.cost_3_v}
                  </div>
                </div>
              </>
            )}
            {character.cost.cost_4 && (
              <>
                <div className="info-list-row">
                  <div className="category">Cost 3</div>
                  <div className="details">
                    <span
                      className={`limbus-affinity ${character.cost.cost_4}`}
                    >
                      {character.cost.cost_4}
                    </span>{' '}
                    x{character.cost.cost_4_v}
                  </div>
                </div>
              </>
            )}
          </Col>
          <Col>
            <h5>Availability</h5>
            <div className="info-list-row">
              <div className="category">Release date</div>
              <div className="details">
                {character.releaseDate ? character.releaseDate : '-'}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="info-list">
          <Col>
            <h5>Specialities</h5>
            <div className="specialities-list">
              {character.speciality ? (
                character.speciality.sort().map((speciality, idx) => {
                  return <SpecialityItem key={idx} speciality={speciality} />;
                })
              ) : (
                <span className="no-spec">-</span>
              )}
            </div>
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </div>
      <div id="section-skills" className="id-section">
        <SectionHeader title="Skills" subtitle="At tier 4" />
        {character.skills ? (
          <>
            <div className={`skills-v2`}>
              <Row xs={1} xl={2} xxl={2}>
                {character.skills.map((skill, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div className={`skill-icon ${skill.type}`}>
                            {skill.isDefensive ? (
                              <>
                                <span className="skill-type pill">Defense</span>
                                <span className="skill-type pill">
                                  {skill.defensiveType}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="skill-type pill">
                                  Skill {index + 1}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">{skill.name}</p>
                            <p className="skill-type">
                              {skill.type && (
                                <span
                                  className={`skill-type pill limbus-affinity-box ${skill.type}`}
                                >
                                  {skill.type}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className={`additional-information`}>
                          <p>
                            Attack Type:{' '}
                            {skill.attackType ? (
                              <span>{skill.attackType}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Skill Power:{' '}
                            {skill.skillPower ? (
                              <span>{skill.skillPower}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            No. of Coins:{' '}
                            {skill.numberOfCoins ? (
                              <span>{skill.numberOfCoins}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Coin Power:{' '}
                            {skill.coinPower ? (
                              <span>{skill.coinPower}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Offense Level:{' '}
                            {skill.offenseLevel ? (
                              <span>{skill.offenseLevel}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Atk Weight:{' '}
                            {skill.growth ? (
                              <span>{skill.growth}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                        </div>
                        <div className={`skill-with-coloring`}>
                          {skill.skillEffect && (
                            <>{renderRichText(skill.skillEffect, options)}</>
                          )}
                        </div>
                        <div className="skill-description-full">
                          {skill.coinEffect && (
                            <>
                              {skill.coinEffect.level_1_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_1_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_1_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_2_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_2_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_2_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_3_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_3_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_3_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_4_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_4_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_4_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_5_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_5_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_5_d
                                    }}
                                  />
                                </div>
                              )}

                              {skill.coinEffect.level_6_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_6_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_6_d
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        ) : (
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>skills aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        )}
      </div>
      <div id="section-passive" className="id-section">
        <SectionHeader title="Passive" />
        <Row className="skills">
          <Col xs={12} xxl={12}>
            <div className="skill-box hsr">
              <div className="skill-header">
                <div className="name-section">
                  <h5 className="name">{character.passiveName}</h5>
                </div>
              </div>
              <div className="skill-content">
                <div className="skill-description">
                  {character.passiveDesc && (
                    <>{renderRichText(character.passiveDesc, options)}</>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div id="section-resistances" className="id-section">
        <SectionHeader title="Resistances" />
        {character.resistances ? (
          <div className="res-row">
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_wrath_big.png"
                alt="Wrath"
              />
              <p>{character.resistances.wrath}</p>
            </div>
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_lust_big.png"
                alt="Lust"
              />
              <p>{character.resistances.lust}</p>
            </div>
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_sloth_big.png"
                alt="Lust"
              />
              <p>{character.resistances.sloth}</p>
            </div>
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_gluttony_big.png"
                alt="Lust"
              />
              <p>{character.resistances.gluttony}</p>
            </div>
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_gloom_big.png"
                alt="Lust"
              />
              <p>{character.resistances.gloom}</p>
            </div>
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_pride_big.png"
                alt="Lust"
              />
              <p>{character.resistances.pride}</p>
            </div>
            <div className="single-res">
              <StaticImage
                src="../images/limbuscompany/icons/affinity_envy_big.png"
                alt="Lust"
              />
              <p>{character.resistances.envy}</p>
            </div>
          </div>
        ) : (
          <Alert variant="primary">
            <p>
              <strong>Resistances are not available for this EGO yet.</strong>{' '}
              We will add them as soon as it is possible!
            </p>
          </Alert>
        )}
      </div>
      <div id="section-gallery" className="id-section">
        <SectionHeader title="Gallery" />
        <h5>Full design (tier 3)</h5>
        {fullImage ? (
          <GatsbyImage image={fullImage} alt="" className="full-image" />
        ) : (
          <Alert variant="primary">
            <Alert.Heading>
              No full image is not available for this EGO yet
            </Alert.Heading>
            <p>We will add it as soon as it is possible!</p>
          </Alert>
        )}
      </div>
    </DashboardLayout>
  );
};

export default LimbusEgoDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Limbus Company | Prydwen Institute'}
      description={
        character.name + ' is one of the EGO available in Limbus Company.'
      }
      image={character.imageFull}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulLimbusEgo(filter: { id: { eq: $contentfulId } }) {
      nodes {
        ...LimbusEgoFieldsFragment
      }
    }
  }
`;
